import { getEnv, resolve } from 'core/utils/env';

import { dark, darkMobile, light, lightMobile } from 'site/themes';

const host = 'letidor.ru';
const siteUrl = 'https://' + host;

/**
 * Этот конфиг вынесен в отдельный файл чтоб шарить конфигурацию сайта
 * с галереей компонентов.
 * Сюда ни в коем случае не должно добавляться что-то кроме конфигурации
 * компонента App.
 */
export default function resolveConfig() {
  return {
    siteName: 'letidor',
    host: resolve({
      '*': host,
      'staging': getEnv('SITE_HOST'),
    }),
    theme: 'light',
    themes: {
      light: {
        base: light,
        mobile: lightMobile,
      },
      dark: {
        base: dark,
        mobile: darkMobile,
      },
    },

    coreApisConfig: {
      bebopApi: resolve({
        'dev': '//s1.letidor.ru/api/v2',
      }),
    },

    webdavHost: resolve({
      '*': getEnv('WEBDAV_HOST'),
      'dev': '//wcdn.stage.unity.rambler.ru/letidor',
    }),

    thumborHost: resolve({
      '*': getEnv('THUMBOR_HOST'),
      'dev': '//s1.thumbor.unity.rambler.tech/unsafe',
    }),

    erratumUrl: resolve({
      '*': `${siteUrl}/erratum`,
      'staging': `https://${getEnv('SITE_HOST')}/erratum`,
    }),

    isUnityProject: true,

    schemaOrg: {
      organizationName: 'Летидор',
      /**
       * Логотип должен быть прямоугольным и лежать в пределах 60x600px:
       * https://developers.google.com/search/docs/data-types/article#logo-guidelines
       */
      organizationLogoUrl: siteUrl + '/icons/publisher-logo.png',
      organizationLogoWidth: 189,
      organizationLogoHeight: 60,
    },

    /**
     * TODO: сюда можно переместить фавиконки. НА всех сайтах иконки будут
     * конфигурироваться в одном месте и не будет необходимости создавать
     * для них отдельные компоненты. Будет сделано отдельной задачей.
     */
    defaultSiteMeta: {
      titleTemplate: '%s - Летидор',
      defaultTitle: 'Сайт для родителей: честно о том, как быть родителем',
      meta: [
        { name: 'description', content: 'Статьи о беременности и родах, уходе за ребёнком, здоровье, психологии, обучении в школе и др. Рекомендации по играм дома и на открытом воздухе. Афиша на выходные и обзоры книг.' },
      ],
    },

    ramblerCommentsAppId: resolve({
      'production': 76,
      '*': 190,
    }),

    ads: {
      siteId: '435055360',
      antiadblock: true,
    },

    counterIds: {
      getSiteControl: 64378,
      gnezdo: 4443,
      googleAnalytics: 'UA-34920536-1',
      ga4: 'G-BBGX8SZTKK',
      tns: { account: 'sup_ru', tmsec: 'letidor_total' },
      mediascope: 'rambler_cid1100108-posid2155105/',
      top100: 2812052,
      yandexMetrika: 17175679,
      mailru: 3090294,
      rcm: resolve({
        '*': 'RCM-176A',
        'production': 'RCM-C9E4',
      }),
      loyalty: true,
      gemius: true,
    },

    features: {
      enableRetina: true,
      disableBlurPreview: true,
      useSmartResizeForSocialImage: true,
      disableAutoplayOnFreeSubdomain: true,
      enableFenwick: true,
    },

    pushWoosh: {
      applicationCode: resolve({
        '*': '02071-8D886',
        'staging': 'E66FE-1C73F',
      }),
      safariWebsitePushID: 'web.ru.letidor',
      defaultNotificationTitle: 'Letidor.ru',
      defaultNotificationImage: siteUrl + '/icons/notification-logo.png',
    },

    specialLinks: [
      '/obrazovanie/etot-legkii-test-pomozhet-podsvetit-sposobnosti-vashego-rebenka.htm',
      '/obrazovanie/5-voprosov-kotorye-raskroyut-potencial-vashego-rebenka.htm',
      '/obrazovanie/kakie-navyki-rebenka-nado-prokachat-uzhe-segodnya.htm',
      '/obrazovanie/eti-navyki-prinesut-vashemu-rebenku-uspekh-v-budushem.htm',
      '/obrazovanie/kakie-navyki-pomogut-vashemu-rebenku-stat-uspeshnym.htm',
    ],

    spammer: {
      ramblerCrmProjectKey: 'letidor_ru',
      termsUrl: 'https://help.rambler.ru/legal/1706/',
      digestKey: 'weekly',
    },

    ramblerId: {
      rname: 'letidor',
    },

    socials: [
      { name: 'Vkontakte', link: 'https://vk.com/letidor' },
      { name: 'Odnoklassniki', link: 'https://ok.ru/letidor' },
      { name: 'Yandex_Dzen', link: 'https://dzen.ru/letidor?favid=1670' },
      { name: 'Youtube', link: 'https://www.youtube.com/channel/UCpZZEq5a5IYmfvZF_xPVlFw/videos' },
      { name: 'Tiktok', link: 'https://www.tiktok.com/@letidor.ru/' },
    ],
  };
}
